.verify-device-actions {
	margin-left: -1rem;
	margin-right: -1rem;

	& > * {
		padding: 0.5rem;
		& > * {
			width: 100%;
		}
	}
}
