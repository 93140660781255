$primary: #174ec0;
$light: #e9e9e9;
$grey: #7e7e7e;
$dark: #363636;
$red: #ff806c;
$orange: #ffe882;
$yellow: #ffe882;
$green: #00dd80;

$theme-colors: (
	primary: $primary,
	light: $light,
	grey: $grey,
	dark: $dark,
	success: $green,
	light-success: lighten($green, 50%),
	info: $yellow,
	warning: $orange,
	danger: $red
);

@import '~bootstrap/scss/bootstrap.scss'; // This import is intentionally put after vars that are used to configure bootstrap themes
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

* {
	// Fonts
	--font-family: 'Roboto', sans-serif;
	--font-size: 1rem;
	--font-weight: 400;

	body {
		background-color: $light;
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
	}

	// Bootstrap overrides
	.alert {
		--bs-alert-margin-bottom: 0;
	}
	small {
		font-size: 80%;
	}
}
